import { formatDateTimeWithLocale, t } from '@/utility/localization';

import { PlanDetails } from '@/pages/user/membership/hooks/useMembershipCurrentPlan';
import { getMembershipPriceString } from '@/pages/user/utils/membershipUtils';

type Props = {
  nextPlan: PlanDetails;
  gracePeriodEndDate?: string;
  hasAutoRenewalFailed?: boolean;
  isAlreadyCancelled?: boolean;
  nextBillingDate?: string;
  trialDays?: number;
};

export default function CardFooterText({
  isAlreadyCancelled = false,
  nextBillingDate = null,
  gracePeriodEndDate = null,
  trialDays = null,
  hasAutoRenewalFailed,
  nextPlan = null
}: Props) {
  if (hasAutoRenewalFailed) {
    return (
      <div className="text-body-xs text-npl-error-light-9">
        {t('auto-renewal-failed')}
      </div>
    );
  }
  if (trialDays) {
    return (
      <div className="text-body-xs text-npl-yellow-light-solid-11">
        {t('free-trial-days-ends-date', {
          days: trialDays,
          date: formatDateTimeWithLocale(
            nextBillingDate || gracePeriodEndDate,
            'dd MMM, yyyy'
          )
        })}
      </div>
    );
  }
  if (nextBillingDate) {
    if (isAlreadyCancelled) {
      return (
        <p className="text-body-xs text-npl-yellow-light-solid-11">
          {`${t('subscription-ends-on')} ${formatDateTimeWithLocale(
            nextBillingDate,
            'dd MMM yyyy'
          )}`}
        </p>
      );
    }
    if (nextPlan && Object.keys(nextPlan)?.length) {
      const amount = getMembershipPriceString({
        currency: nextPlan?.currency,
        discountDetails: nextPlan?.discountDetails,
        amount: nextPlan?.amount,
        interval: nextPlan?.interval,
        intervalCount: nextPlan?.intervalCount
      });

      return (
        <p className="text-body-xs text-npl-text-icon-on-light-surface-secondary">
          {t('membership-plan-will-renew-at-price-on-date', {
            price: String(amount),
            date: formatDateTimeWithLocale(nextBillingDate, 'dd MMM yyyy')
          })}
        </p>
      );
    }

    return (
      <p className="text-body-xs text-npl-text-icon-on-light-surface-secondary">
        {`${t('next-billing-date')}: ${formatDateTimeWithLocale(
          nextBillingDate,
          'dd MMM yyyy'
        )}`}
      </p>
    );
  }

  return <></>;
}
