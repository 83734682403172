import Auth from '@/modules/Auth';
import axios from 'axios';

import { getLocaleFromCookie } from '@/utility/localization';

import { getTokensService } from '../userService';

const protectedAxiosRequest = axios.create();

const protectedReqConfigMiddleware = (config) => {
  const locale = getLocaleFromCookie();

  if (locale && config?.headers) {
    config.headers['Accept-Language'] = locale;
  }

  if (config?.headers.Authorization) {
    return config;
  }

  if (Auth.getUserToken()) {
    const accessToken = `Bearer ${Auth.getUserToken()}`;
    const argHeaders = config?.headers || {};
    config.headers = {
      Authorization: accessToken,
      ...argHeaders
    };
    return config;
  }

  return Promise.reject('No Token');
};

const protectedReqErrorMiddleware = (error) => {
  return Promise.reject(error);
};

const protectedResMiddleware = (response) => response;
const protectedResErrorMiddleware = async (resError) => {
  try {
    const status = resError?.response?.status;
    const originalRequest = resError.config;
    const refreshToken = Auth.getRefreshToken();

    if (refreshToken && status === 401) {
      const payload = { refreshToken };
      const { data, error } = await getTokensService(payload);
      if (error) {
        return Promise.reject(error);
      }

      const { token } = data;

      if (!token) throw new Error('No Access Token Found.');

      originalRequest.headers.Authorization = `Bearer ${token}`;
      return protectedAxiosRequest(originalRequest);
    }

    return Promise.reject(resError);
  } catch (error) {
    return Promise.reject(error);
  }
};

// Request interceptors
protectedAxiosRequest.interceptors.request.use(
  protectedReqConfigMiddleware,
  protectedReqErrorMiddleware
);

// Response interceptors
protectedAxiosRequest.interceptors.response.use(
  protectedResMiddleware,
  protectedResErrorMiddleware
);

export default protectedAxiosRequest;
