import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { t } from '@/utility/localization';

import { cancelCommunitySubscription } from '@/services/member/cancelSubscriptionService';
import { fetchSpecificUserMembership } from '@/services/user/membershipService';

import {
  showErrorToast,
  showToast
} from '@/components/common/ToastContainer';

import { SETTINGS_PAGE_MEMBERSHIP_PATH } from '../../constants';
import { MembershipObject } from './types';

const useMembershipDetails = ({ subscriptionId }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [isLoadingMemberships, setIsLoadingMemberships] =
    useState<boolean>(true);
  const [membershipData, setMembershipData] = useState<MembershipObject>(
    {}
  );
  const [loadingCancellation, setLoadingCancellation] =
    useState<boolean>(false);

  const getUserMembership = async () => {
    const { data, error } = await fetchSpecificUserMembership({
      subscriptionId: subscriptionId
    });

    if (error && pathname.includes(SETTINGS_PAGE_MEMBERSHIP_PATH)) {
      router.push(SETTINGS_PAGE_MEMBERSHIP_PATH);
      return;
    }

    setMembershipData(data?.data);
    setIsLoadingMemberships(false);
  };

  const onCancelSubscriptionClick = async (cancellationReason) => {
    const {
      title: communityName,
      code: communityCode,
      communityId: selectedCommunityId
    } = membershipData?.community || {};

    setLoadingCancellation(true);
    const { error } = await cancelCommunitySubscription({
      cancellationReason,
      communityCode,
      communityId: selectedCommunityId,
      subscriptionObjectId: membershipData?.subscriptionId
    });
    if (error) {
      showErrorToast(error);
      setLoadingCancellation(false);
      return;
    }
    getUserMembership();
    showToast({
      text: t('successfully-left-communityname', { communityName })
    });

    setLoadingCancellation(false);
  };

  useEffect(() => {
    if (subscriptionId) {
      getUserMembership();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId]);

  return {
    isLoadingMemberships,
    membershipData,
    loadingCancellation,
    onCancelSubscriptionClick,
    getUserMembership
  };
};

export default useMembershipDetails;
