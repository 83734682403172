import config from '@/utility/config';

import { getAxiosErrorMsg } from '../helpers';
import adminProtectedAxiosRequest from '../helpers/adminProtectedAxiosRequest';

export const cancelCommunitySubscription = async ({
  cancellationReason,
  communityCode,
  communityId,
  subscriptionObjectId
}) => {
  const { data, error } = await adminProtectedAxiosRequest.put(
    `${config.communityApiBasePath}/api/v1/communities/leave-community/${communityId}`,
    {
      cancellationReason,
      communityCode,
      subscriptionObjectId
    }
  );

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data, error: null };
};
