import { t } from '@/utility/localization';

import { DISCOUNT_ENTITY_TYPE } from '@/hooks/useDiscount';

export const getDiscountTagText = ({
  intervalCount,
  value,
  trialDays,
  discountType,
  interval = null
}) => {
  if (trialDays) {
    if (trialDays === 30) {
      return t('1-month-free-trial');
    } else {
      return t('trialdays-days-free-trial', { trialDays });
    }
  } else {
    if (intervalCount && discountType !== 'FOREVER') {
      return t('percent-off-interval', {
        percent: value,
        interval:
          intervalCount === 12 || interval === 'year'
            ? t('first-year-0')
            : intervalCount > 1
              ? t('first-interval', {
                  interval: `${intervalCount} ${t('months-0')}`
                })
              : t('first-month-0')
      });
    } else {
      return t('percent-off-interval', {
        percent: value,
        interval: t('forever-0')
      });
    }
  }
};

export const getMembershipDiscountText = (props) => {
  const { intervalCount, value, trialDays } = props || {};
  // Ensure backward compatibility by making all that does not have a intervalAccount = forever/EY
  if (trialDays) {
    if (trialDays === 30) {
      return t('1-month-free-trial');
    } else {
      return t('trialdays-days-free-trial', { trialDays });
    }
  } else {
    if (!intervalCount || intervalCount === '0') {
      return t('value-forever', { percentage: value });
    }

    const intervalText = {
      1: [t('month-lowercase')],
      3: [3, t('months-lowercase')],
      6: [6, t('months-lowercase')],
      12: [t('year-lowercase')]
    };
    return t('percentage-discount-tag-1', {
      percentage: value,
      intervalText: intervalText[intervalCount].join(' ')
    });
  }
};

export const getFullDiscountText = (discount) => {
  const { intervalCount, value, trialDays, linkedEntities } =
    discount || {};

  //All products discount
  if (
    !linkedEntities ||
    !linkedEntities.length ||
    (linkedEntities.length === 1 &&
      linkedEntities[0].type === DISCOUNT_ENTITY_TYPE.ALL)
  ) {
    return [
      t('discountedcodedata-value-off', {
        percentage: value
      }),
      t('everything')
    ].join(' ');
  }

  //Subscription discount
  if (
    (linkedEntities.length === 1 &&
      linkedEntities[0].type === DISCOUNT_ENTITY_TYPE.SUBSCRIPTION) ||
    trialDays
  ) {
    return getMembershipDiscountText({ intervalCount, value, trialDays });
  }

  //Product discount
  if (linkedEntities.length === 1) {
    return [
      t('discountedcodedata-value-off', {
        percentage: value
      }),
      linkedEntities[0].title
    ].join(' ');
  }

  if (linkedEntities.length > 1) {
    return [
      t('discountedcodedata-value-off', {
        percentage: value
      }),
      t('count-items', {
        count: linkedEntities.length
      })
    ].join(' ');
  }

  return [
    t('discountedcodedata-value-off', {
      percentage: value
    }),
    t('everything')
  ].join(' ');
};
