import classNames from 'classnames';
import { useRouter } from 'next/navigation';
import React, { FC } from 'react';

import { formatDateTimeWithLocale, t } from '@/utility/localization';
import withComponentClassName from '@/utility/withComponentClassName';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import { getDiscountTagText } from '@/components/common/DiscountCreationDisplay/util';
import NPLBadge, { NPL_BADGE_HIERARCHY } from '@/components/npl/NPLBadge';
import NPLButton from '@/components/npl/NPLButton';

import { SETTINGS_PAGE_MEMBERSHIP_PATH } from '@/pages/user/constants';
import CardFooterText from '@/pages/user/layouts/MembershipPageContent/components/ActiveMembershipCard/components/CardFooterText';
import { CommunitySubscriptionType } from '@/pages/user/layouts/MembershipPageContent/components/ActiveMembershipCard/type';
import useMembershipCurrentPlan from '@/pages/user/membership/hooks/useMembershipCurrentPlan';
import { getMembershipPriceString } from '@/pages/user/utils/membershipUtils';

const MembershipCard: FC<{
  subscription: CommunitySubscriptionType;
}> = ({ subscription }) => {
  const { isGtEqMd } = useWindowWidthContext();

  const {
    subscriptionId,
    nextBillingDate,
    paymentDetails,
    discountDetails,
    status
  } = subscription || {};

  const router = useRouter();

  const isAlreadyCancelled = status === 'Cancelled';

  const hasAutoRenewalFailed = paymentDetails?.status === 'renewal_failed';

  const { currentPlan, nextPlan } = useMembershipCurrentPlan({
    membershipData: subscription
  });

  const membershipPricingText = getMembershipPriceString({
    discountDetails: currentPlan?.discountDetails,
    amount: currentPlan?.amount,
    interval: currentPlan?.interval,
    currency: currentPlan?.currency,
    intervalCount: currentPlan?.intervalCount
  });

  const hasDiscount =
    currentPlan?.discountDetails &&
    currentPlan?.discountDetails?.discountType !== 'FREE_TRIAL';

  const routeToMembershipPage = () => {
    router.push(`${SETTINGS_PAGE_MEMBERSHIP_PATH}/${subscriptionId}`);
  };

  return (
    <div
      className={classNames(
        'rounded-16 border-1 border-npl-neutral-light-solid-4 bg-white-default transition-all',
        {
          'hover:cursor-pointer hover:bg-npl-neutral-light-solid-6':
            hasAutoRenewalFailed
        }
      )}>
      <div
        onClick={hasAutoRenewalFailed ? routeToMembershipPage : null}
        className=" flex flex-col justify-between px-24 py-20 md:flex-row md:items-center ">
        <div className="flex gap-16 md:items-center">
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 md:items-center">
              <p className="text-heading-xs font-semibold">
                {membershipPricingText}
              </p>
              {hasDiscount && (
                <NPLBadge
                  type={NPL_BADGE_HIERARCHY.SUCCESS}
                  text={getDiscountTagText({
                    value: currentPlan?.discountDetails?.percentage,
                    trialDays: currentPlan?.discountDetails?.trialDays,
                    intervalCount: currentPlan?.intervalCount,
                    discountType:
                      currentPlan?.discountDetails?.discountType,
                    interval: currentPlan?.interval
                  })}
                />
              )}
            </div>
            <CardFooterText
              isAlreadyCancelled={isAlreadyCancelled}
              nextBillingDate={nextBillingDate}
              trialDays={discountDetails?.trialDays}
              hasAutoRenewalFailed={hasAutoRenewalFailed}
              nextPlan={nextPlan}
            />
          </div>
        </div>
        <div className="flex w-full gap-4 md:w-fit">
          {hasAutoRenewalFailed ? (
            <>
              <div className="mt-16 w-full md:mt-0">
                <NPLButton
                  stretch={!isGtEqMd}
                  hierarchy="neutral_primary"
                  buttonText={t('renew')}
                  size="md"
                  onClick={() =>
                    router.push(
                      subscription?.paymentDetails
                        ?.failedPaymentRenewalLink
                    )
                  }
                />
              </div>
              {isGtEqMd && (
                <NPLButton
                  rounded
                  hierarchy="plain"
                  leadIcon="chevron-right"
                  isSecondary
                  size="md"
                  onClick={routeToMembershipPage}
                />
              )}
            </>
          ) : (
            <div className="mt-16 w-full md:mt-0">
              <NPLButton
                size="md"
                stretch={!isGtEqMd}
                buttonText={t('manage')}
                hierarchy="neutral_primary"
                onClick={routeToMembershipPage}
              />
            </div>
          )}
        </div>
      </div>
      {hasAutoRenewalFailed && (
        <div className="rounded-b-16 bg-npl-neutral-light-solid-3 px-16 py-12">
          <div className="text-body-xs text-npl-text-icon-on-light-surface-secondary">
            {t(
              'update-your-payment-method-before-17-aug-to-to-ensure-you-do-not-lose-access-to-your-community',
              {
                date: formatDateTimeWithLocale(
                  paymentDetails?.gracePeriodEndDate,
                  'dd MMM'
                )
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withComponentClassName(MembershipCard);
