import classNames from 'classnames';
import React from 'react';

import { t } from '@/utility/localization';

import NextImage from '@/components/common/NextImage';

import useMembershipDetails from '@/pages/user/membership/hooks/useMembershipDetails';

import AboutCommunity from '../components/about/AboutCommunity';
import MembershipCard from '../components/people/MembershipCard';

function AboutTab({
  communityInfo,
  activeCommunity,
  userCommunitiesData
}) {
  const { isCommunityAdmin } = userCommunitiesData || {};

  const { backgroundImage, profileImage, title, description, host } =
    communityInfo || {};

  const { membershipData, isLoadingMemberships } = useMembershipDetails({
    subscriptionId: activeCommunity?.subscriptions?.[0]?._id
  });

  return (
    <div>
      <div className="mb-40 flex flex-col justify-center">
        {/* Banner */}
        <NextImage
          className="aspect-h-9 aspect-w-16 md:rounded-16"
          mobileImgProps={{
            src: backgroundImage,
            layout: 'fill',
            objectFit: 'cover',
            priority: true,
            quality: 100
          }}
        />
        {/* Community Image */}
        <div
          className={classNames(`relative mx-auto `, {
            'mt-[-40px]': backgroundImage
          })}>
          <div className="flex h-90 w-90 items-center justify-center rounded-16 bg-white-default">
            <NextImage
              className="m-auto h-80 w-80 rounded-16"
              mobileImgProps={{
                src: profileImage,
                width: 80,
                height: 80,
                objectFit: 'cover',
                priority: true,
                quality: 100
              }}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center px-16">
          {/* Community Title and HostName */}
          <div className="mx-auto mt-16 text-center">
            <h1 className="text-heading-xl font-semibold">{title}</h1>
            <p className="mt-4 text-label-md text-npl-text-icon-on-light-surface-primary">
              {t('by')} {host?.name}
            </p>
          </div>
        </div>
        {!isCommunityAdmin && (
          <>
            <div className="mx-24 my-32 h-[1px] bg-npl-neutral-light-solid-4"></div>
            <div className="mb-32 px-16 md:px-24">
              <div className="mb-16 text-heading-md font-semibold">
                {t('your-membership')}
              </div>
              {isLoadingMemberships ? (
                <div
                  key={t}
                  className="max-w-[675px] animate-pulse rounded-12 bg-npl-neutral-light-solid-2 p-16 shadow-npl-styles-button-shadow">
                  <div className="flex items-center space-x-16">
                    <div className="h-64 w-64 rounded-12 bg-npl-neutral-light-solid-6"></div>
                    <div className="flex-1">
                      <div className="mb-4 h-16 w-[300px] rounded bg-npl-neutral-light-solid-6"></div>
                      <div className="mb-4 h-16 w-[200px] rounded bg-npl-neutral-light-solid-6"></div>
                      <div className="h-16 w-[250px] rounded bg-npl-neutral-light-solid-6"></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-16 w-full">
                  <MembershipCard subscription={membershipData} />
                </div>
              )}
            </div>
          </>
        )}

        {description && (
          <div className="mt-32 px-16 md:px-24">
            <AboutCommunity communityPublicPageData={communityInfo} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AboutTab;
