import classNames from 'classnames';
import React from 'react';

import withComponentClassName from '@/utility/withComponentClassName';

function AnimatedShowHideDiv({
  children,
  show,
  duration = 200,
  direction = 'vertical' // 'vertical' or 'horizontal'
}) {
  const getAnimationClasses = () => {
    if (direction === 'horizontal') {
      return {
        wrapper: classNames(
          `grid w-full transition-all duration-${duration} overflow-hidden ease-out`,
          {
            'grid-cols-[0fr]': !show,
            'grid-cols-[1fr]': show
          }
        ),
        inner: 'overflow-hidden'
      };
    }

    return {
      wrapper: classNames(
        `grid w-full transition-all duration-${duration} overflow-hidden ease-out`,
        {
          'grid-rows-[0fr]': !show,
          'grid-rows-[1fr]': show
        }
      ),
      inner: 'overflow-hidden'
    };
  };

  const classes = getAnimationClasses();

  return (
    <div className={classes.wrapper}>
      <div className={classes.inner}>{children}</div>
    </div>
  );
}

export default withComponentClassName(AnimatedShowHideDiv);
