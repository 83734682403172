import { useRouter } from 'next/router';
import React from 'react';

import { TAB_PAGE_VIEW_EVENT_NAME } from '@/components/features/CommunityPage/constants';
import useTrackTabPageView from '@/components/features/CommunityPage/hooks/useTrackTabPageView';
import CommunityPageLayout from '@/components/features/CommunityPage/layouts/CommunityPageLayout';
import AboutTab from '@/components/features/CommunityPage/tabContents/AboutTab';
import AuthNavbarPageLayout from '@/components/features/Navbar/AuthNavbarPageLayout';

import { getCommunityStaticProps } from './utils';

const CommunityAboutPage = ({
  userCommunitiesData,
  communityPublicPageData,
  activeCommunity
}) => {
  const { isCommunityMember, fetchingUserCommunities } =
    userCommunitiesData;
  const communitySlug = communityPublicPageData?.slug;

  const router = useRouter();
  useTrackTabPageView({
    eventName: TAB_PAGE_VIEW_EVENT_NAME.ABOUT,
    communityId: communityPublicPageData?._id
  });

  // handle unAuthedUser
  if (router?.isReady && !isCommunityMember && !fetchingUserCommunities) {
    router.push(`/${communitySlug}/home`);
  }

  return (
    <div className="c-CommunityLandingPage-CommunityAbout-Tab py-32 ">
      <AboutTab
        communityInfo={communityPublicPageData}
        activeCommunity={activeCommunity}
        userCommunitiesData={userCommunitiesData}
      />
    </div>
  );
};

CommunityAboutPage.getLayout = function getLayout(page) {
  const communityPublicPageData = page.props.communityPublicPageData;
  return (
    <AuthNavbarPageLayout communityInfo={communityPublicPageData}>
      {(userCommunitiesData) => (
        <CommunityPageLayout
          communityPublicPageData={communityPublicPageData}
          showBanner={false}
          userCommunitiesData={userCommunitiesData}>
          {React.cloneElement(page, { userCommunitiesData })}
        </CommunityPageLayout>
      )}
    </AuthNavbarPageLayout>
  );
};

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking'
  };
}

export const getStaticProps = getCommunityStaticProps;

export default CommunityAboutPage;
